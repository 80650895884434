
import "./radio-v1"
import "./checkbox-v1"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class ProductCardV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const isChecked = this.isChecked ?? null ;
const product = this.product ?? null ;
const variant = this.variant ?? null ;
const selected_price = this.selected_price ?? null ;
const select_style = this.select_style ?? null ;
const design_style = this.design_style ?? null ;
const show_price = this.show_price ?? null ;
const show_image = this.show_image ?? null ;
const show_description = this.show_description ?? null ;
const show_compare_at_price = this.show_compare_at_price ?? null ;
const product_select_type = this.product_select_type ?? null ;
const useCheckboxIcon = this.useCheckboxIcon ?? null ;
const isFormItem = this.isFormItem ?? null ;

      
      let html = '';
      {
        const customVariants = product.variants.filter((value) => value["default_variant"] == false);
        if (product_select_type == "bump") {
          const product_name = "product_bump";
          if (design_style == "card") {
            html += `<div class="elProductCardCol">`
            if (design_style == "classic") {
              html += `<div class="elProductCardRow"><div class="elProductCardCTA"><div class="elProductCardSelectInput`
              if (design_style != "classic") {
                html += ` elHide`
              }
              html += `">`
              if (select_style == "single") {
                if (isChecked) {
                  html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="true" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product_bump" checked class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
                } else {
                  html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product_bump"  class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
                }
              } else if (select_style == "multiple") {
                if (isChecked) {
                  html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked="true"><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product_bump" checked data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
                  if (useCheckboxIcon) {
                    html += ` hide`
                  }
                  html += `"></div><i class="elCheckboxIconCheck fas fa-check`
                  if (useCheckboxIcon == false) {
                    html += ` hide`
                  }
                  html += `"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
                } else {
                  html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked=""><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product_bump"  data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
                  if (useCheckboxIcon) {
                    html += ` hide`
                  }
                  html += `"></div><i class="elCheckboxIconCheck fas fa-check`
                  if (useCheckboxIcon == false) {
                    html += ` hide`
                  }
                  html += `"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
                }
              }
              html += `</div>`
              if (select_style == "quantity" || select_style == "quantity-forced") {
                html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked=""><label class="elCheckboxLabel"><input type="checkbox" value="" name="cta-checkbox"  data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
                if (useCheckboxIcon) {
                  html += ` hide`
                }
                html += `"></div><i class="elCheckboxIconCheck fas fa-check`
                if (useCheckboxIcon == false) {
                  html += ` hide`
                }
                html += `"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
              }
              html += `<span>${product.bump_preheadline ?? ""}</span></div></div>`
            } else {
              html += `<div class="elProductCardSelectInput`
              if (design_style != "classic") {
                html += ` elHide`
              }
              html += `">`
              if (select_style == "single") {
                if (isChecked) {
                  html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="true" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product_bump" checked class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
                } else {
                  html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product_bump"  class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
                }
              } else if (select_style == "multiple") {
                if (isChecked) {
                  html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked="true"><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product_bump" checked data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
                  if (useCheckboxIcon) {
                    html += ` hide`
                  }
                  html += `"></div><i class="elCheckboxIconCheck fas fa-check`
                  if (useCheckboxIcon == false) {
                    html += ` hide`
                  }
                  html += `"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
                } else {
                  html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked=""><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product_bump"  data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
                  if (useCheckboxIcon) {
                    html += ` hide`
                  }
                  html += `"></div><i class="elCheckboxIconCheck fas fa-check`
                  if (useCheckboxIcon == false) {
                    html += ` hide`
                  }
                  html += `"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
                }
              }
              html += `</div>`
            }
            html += `<div class="elProductCardRow">`
            if (show_image) {
              const imageUrl = variant.image ?? product.image;
              if (imageUrl && imageUrl != "") {
                html += `<div class="elProductCardImage" data-desktop-only="true"><div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
                if (true && !imageUrl && !null && false == false) {
                  html += ` forceHide`
                }
                html += `" data-liquid-replace="item">`
                if (imageUrl || !!null) {
                  html += `<img class="elImage" src="${imageUrl ?? null}"/>`
                } else if (false) {
                  html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
                }
                html += `</div></div>`
              }
            }
            html += `<div class="elProductCardInfoContainer">`
            if (show_image) {
              const imageUrl = variant.image ?? product.image;
              if (imageUrl && imageUrl != "") {
                html += `<div class="elProductCardImage" data-mobile-only="true"><div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
                if (true && !imageUrl && !null && false == false) {
                  html += ` forceHide`
                }
                html += `" data-liquid-replace="item">`
                if (imageUrl || !!null) {
                  html += `<img class="elImage" src="${imageUrl ?? null}"/>`
                } else if (false) {
                  html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
                }
                html += `</div></div>`
              }
            }
            if (design_style != "classic") {
              html += `<div class="elProductCardCTA">${product.bump_preheadline ?? ""}</div>`
            }
            html += `<span><span class="elProductBumpHeadline">`
            if (customVariants.length > 1) {
            html += `${product.default_variant.name ?? "One time offer:"}`
            } else {
            html += `${variant.name ?? "One time offer:"}`
            }
            if (show_price) {
              html += `<span class="elProductCardPrice"> (${selected_price.name})</span>`
            }
            html += `</span><span class="elProductBumpText">${product.description_override ?? variant.description ?? product.default_variant.description ?? ""}</span></span><div class="elProductVariantSelectorWrapper">`
            if (customVariants.length > 1) {
              const c0 = product.sorted_property_values
              const fl1 = new CF2ForloopDrop(c0.length)
              for (const property_values of c0) {
                const forloop = fl1
                const property_id = property_values.property_id;
                const select_index = forloop.index0;
                html += `<div class="elProductVariantSelectorOptionWrapper"><span class="elProductVariantSelectorTitle">${product.all_properties[property_id]}</span><div class="elSelectWrapper"><select class="elProductInputControls elProductCardInput elProductVariantSelectorSelect" name="property_${property_id}">`
                const c2 = property_values.value_ids
                const fl3 = new CF2ForloopDrop(c2.length)
                for (const value_id of c2) {
                  const forloop = fl3
                  const new_value_ids = variant.property_value_ids.map((value, valueIndex) => select_index == valueIndex ? value_id : value).join(",");
                  const isSelected = variant.property_value_ids[select_index] == value_id;
                  const isUnavailable = product.property_values_variant_mapping[new_value_ids] == null;
                  html += `<option `
                  if (variant.property_value_ids[select_index] == value_id) {
                    html += `selected`
                  }
                  html += ` class="elProductVariantSelectorSelectOption" value="${value_id}">${product.all_properties_values[value_id]}`
                  if (isUnavailable) {
                    html += ` (unavailable)`
                  }
                  html += `</option>`
                  forloop.next();
                }
                html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div></div>`
                forloop.next();
              }
            }
            html += `</div><div class="elProductCardInfoSelectionAndPrices">`
            if (select_style == "quantity") {
              html += `<div class="elProductInputWrapper elProductCardQuantityContainer"><span>Quantity</span><div class="elProductInputControls"><button class="elProductCardInput">-</button><input class="elProductCardInput elProductCardShortInput" name="product_bump" value="0" min="0" type="number"/><button class="elProductCardInput">+</button></div></div>`
            } else if (select_style == "quantity-forced") {
              html += `<div class="elProductInputWrapper elSelectWrapper elForcedQuantityWrapper"><span>Quantity</span><select name="product_bump" class="elProductCardInput elProductInputControls">`
              const step = product.step ?? 2;
              const min = product.min ?? 0;
              const max = product.max ?? 5;
              const item_count_step_1 = max- min;
              const item_count_step_2 = item_count_step_1/ step;
              const item_count_step_3 = item_count_step_2| 0;
              const range = Array.apply(0, Array(item_count_step_3)).map((element, index) => index + 0);;
              const c4 = range
              const fl5 = new CF2ForloopDrop(c4.length)
              for (const i of c4) {
                const forloop = fl5
                const value = i* step+ min;
                html += `<option value="${value}">${value}</option>`
                forloop.next();
              }
              html += `</select><div class="elSelectArrow elForcedQuantitySelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
            } else {
              if (select_style == "multiple" && design_style != "classic") {
                html += `<div class="elProductCardModernInput"><span class="elButtonText"></span><span class="elButtonSub"></span></div>`
              }
            }
            const prices_count = variant.prices.length;
            if (prices_count > 1) {
              html += `<div class="elProductInputWrapper elSelectWrapper"><select name="variant_price" class="elProductCardPriceSelect elProductInputControls elProductCardInput">`
              const c6 = variant.prices
              const fl7 = new CF2ForloopDrop(c6.length)
              for (const price of c6) {
                const forloop = fl7
                html += `<option `
                if (selected_price.id == price.id) {
                  html += `selected`
                }
                html += ` data-compare-at-price="${price.compare_at_amount}" value="${price.id}">${price.name}</option>`
                forloop.next();
              }
              html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
            }
            html += `</div></div></div></div>`
          } else {
            html += `<div class="elProductCardCol">`
            if (design_style == "classic") {
              html += `<div class="elProductCardRow"><div class="elProductCardCTA"><div class="elProductCardSelectInput`
              if (design_style != "classic") {
                html += ` elHide`
              }
              html += `">`
              if (select_style == "single") {
                if (isChecked) {
                  html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="true" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product_bump" checked class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
                } else {
                  html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product_bump"  class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
                }
              } else if (select_style == "multiple") {
                if (isChecked) {
                  html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked="true"><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product_bump" checked data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
                  if (useCheckboxIcon) {
                    html += ` hide`
                  }
                  html += `"></div><i class="elCheckboxIconCheck fas fa-check`
                  if (useCheckboxIcon == false) {
                    html += ` hide`
                  }
                  html += `"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
                } else {
                  html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked=""><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product_bump"  data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
                  if (useCheckboxIcon) {
                    html += ` hide`
                  }
                  html += `"></div><i class="elCheckboxIconCheck fas fa-check`
                  if (useCheckboxIcon == false) {
                    html += ` hide`
                  }
                  html += `"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
                }
              }
              html += `</div>`
              if (select_style == "quantity" || select_style == "quantity-forced") {
                html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked=""><label class="elCheckboxLabel"><input type="checkbox" value="" name="cta-checkbox"  data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
                if (useCheckboxIcon) {
                  html += ` hide`
                }
                html += `"></div><i class="elCheckboxIconCheck fas fa-check`
                if (useCheckboxIcon == false) {
                  html += ` hide`
                }
                html += `"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
              }
              html += `<span>${product.bump_preheadline ?? ""}</span></div></div>`
            } else {
              html += `<div class="elProductCardSelectInput`
              if (design_style != "classic") {
                html += ` elHide`
              }
              html += `">`
              if (select_style == "single") {
                if (isChecked) {
                  html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="true" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product_bump" checked class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
                } else {
                  html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product_bump"  class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
                }
              } else if (select_style == "multiple") {
                if (isChecked) {
                  html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked="true"><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product_bump" checked data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
                  if (useCheckboxIcon) {
                    html += ` hide`
                  }
                  html += `"></div><i class="elCheckboxIconCheck fas fa-check`
                  if (useCheckboxIcon == false) {
                    html += ` hide`
                  }
                  html += `"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
                } else {
                  html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked=""><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product_bump"  data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
                  if (useCheckboxIcon) {
                    html += ` hide`
                  }
                  html += `"></div><i class="elCheckboxIconCheck fas fa-check`
                  if (useCheckboxIcon == false) {
                    html += ` hide`
                  }
                  html += `"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
                }
              }
              html += `</div>`
            }
            html += `<div class="elProductCardRow">`
            if (show_image) {
              const imageUrl = variant.image ?? product.image;
              if (imageUrl && imageUrl != "") {
                html += `<div class="elProductCardImage" data-desktop-only="true"><div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
                if (true && !imageUrl && !null && false == false) {
                  html += ` forceHide`
                }
                html += `" data-liquid-replace="item">`
                if (imageUrl || !!null) {
                  html += `<img class="elImage" src="${imageUrl ?? null}"/>`
                } else if (false) {
                  html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
                }
                html += `</div></div>`
              }
            }
            html += `<div class="elProductCardInfoContainer">`
            if (show_image) {
              const imageUrl = variant.image ?? product.image;
              if (imageUrl && imageUrl != "") {
                html += `<div class="elProductCardImage" data-mobile-only="true"><div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
                if (true && !imageUrl && !null && false == false) {
                  html += ` forceHide`
                }
                html += `" data-liquid-replace="item">`
                if (imageUrl || !!null) {
                  html += `<img class="elImage" src="${imageUrl ?? null}"/>`
                } else if (false) {
                  html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
                }
                html += `</div></div>`
              }
            }
            if (design_style != "classic") {
              html += `<div class="elProductCardCTA">${product.bump_preheadline ?? ""}</div>`
            }
            html += `<span><span class="elProductBumpHeadline">`
            if (customVariants.length > 1) {
            html += `${product.default_variant.name ?? "One time offer:"}`
            } else {
            html += `${variant.name ?? "One time offer:"}`
            }
            if (show_price) {
              html += `<span class="elProductCardPrice"> (${selected_price.name})</span>`
            }
            html += `</span><span class="elProductBumpText">${product.description_override ?? variant.description ?? product.default_variant.description ?? ""}</span></span><div class="elProductVariantSelectorWrapper">`
            if (customVariants.length > 1) {
              const c8 = product.sorted_property_values
              const fl9 = new CF2ForloopDrop(c8.length)
              for (const property_values of c8) {
                const forloop = fl9
                const property_id = property_values.property_id;
                const select_index = forloop.index0;
                html += `<div class="elProductVariantSelectorOptionWrapper"><span class="elProductVariantSelectorTitle">${product.all_properties[property_id]}</span><div class="elSelectWrapper"><select class="elProductInputControls elProductCardInput elProductVariantSelectorSelect" name="property_${property_id}">`
                const c10 = property_values.value_ids
                const fl11 = new CF2ForloopDrop(c10.length)
                for (const value_id of c10) {
                  const forloop = fl11
                  const new_value_ids = variant.property_value_ids.map((value, valueIndex) => select_index == valueIndex ? value_id : value).join(",");
                  const isSelected = variant.property_value_ids[select_index] == value_id;
                  const isUnavailable = product.property_values_variant_mapping[new_value_ids] == null;
                  html += `<option `
                  if (variant.property_value_ids[select_index] == value_id) {
                    html += `selected`
                  }
                  html += ` class="elProductVariantSelectorSelectOption" value="${value_id}">${product.all_properties_values[value_id]}`
                  if (isUnavailable) {
                    html += ` (unavailable)`
                  }
                  html += `</option>`
                  forloop.next();
                }
                html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div></div>`
                forloop.next();
              }
            }
            html += `</div><span class="elProductCardInfoSelectionAndPrices">`
            if (select_style == "quantity") {
              html += `<div class="elProductInputWrapper elProductCardQuantityContainer"><span>Quantity</span><div class="elProductInputControls"><button class="elProductCardInput">-</button><input class="elProductCardInput elProductCardShortInput" name="product_bump" value="0" min="0" type="number"/><button class="elProductCardInput">+</button></div></div>`
            } else if (select_style == "quantity-forced") {
              html += `<div class="elProductInputWrapper elSelectWrapper elForcedQuantityWrapper"><span>Quantity</span><select name="product_bump" class="elProductCardInput elProductInputControls">`
              const step = product.step ?? 2;
              const min = product.min ?? 0;
              const max = product.max ?? 5;
              const item_count_step_1 = max- min;
              const item_count_step_2 = item_count_step_1/ step;
              const item_count_step_3 = item_count_step_2| 0;
              const range = Array.apply(0, Array(item_count_step_3)).map((element, index) => index + 0);;
              const c12 = range
              const fl13 = new CF2ForloopDrop(c12.length)
              for (const i of c12) {
                const forloop = fl13
                const value = i* step+ min;
                html += `<option value="${value}">${value}</option>`
                forloop.next();
              }
              html += `</select><div class="elSelectArrow elForcedQuantitySelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
            } else {
              if (select_style == "multiple" && design_style != "classic") {
                html += `<div class="elProductCardModernInput"><span class="elButtonText"></span><span class="elButtonSub"></span></div>`
              }
            }
            const prices_count = variant.prices.length;
            if (prices_count > 1) {
              html += `<div class="elProductInputWrapper elSelectWrapper"><select name="variant_price" class="elProductCardPriceSelect elProductInputControls elProductCardInput">`
              const c14 = variant.prices
              const fl15 = new CF2ForloopDrop(c14.length)
              for (const price of c14) {
                const forloop = fl15
                html += `<option `
                if (selected_price.id == price.id) {
                  html += `selected`
                }
                html += ` data-compare-at-price="${price.compare_at_amount}" value="${price.id}">${price.name}</option>`
                forloop.next();
              }
              html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
            }
            html += `</span></div></div></div>`
          }
        } else {
          const product_name = "product";
          if (design_style == "card") {
            html += `<div class="elProductCardRow"><div class="elProductCardSelectInput`
            if (design_style != "classic") {
              html += ` elHide`
            }
            html += `">`
            if (select_style == "single") {
              if (isChecked) {
                html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="true" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product" checked class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
              } else {
                html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product"  class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
              }
            } else if (select_style == "multiple") {
              if (isChecked) {
                html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked="true"><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product" checked data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
                if (useCheckboxIcon) {
                  html += ` hide`
                }
                html += `"></div><i class="elCheckboxIconCheck fas fa-check`
                if (useCheckboxIcon == false) {
                  html += ` hide`
                }
                html += `"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
              } else {
                html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked=""><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product"  data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
                if (useCheckboxIcon) {
                  html += ` hide`
                }
                html += `"></div><i class="elCheckboxIconCheck fas fa-check`
                if (useCheckboxIcon == false) {
                  html += ` hide`
                }
                html += `"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
              }
            }
            html += `</div><div class="elProductCardImageInfoContainer">`
            if (show_image) {
              const imageUrl = variant.image ?? product.image;
              if (imageUrl && imageUrl != "") {
                html += `<div class="elProductCardImage"><div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
                if (true && !imageUrl && !null && false == false) {
                  html += ` forceHide`
                }
                html += `" data-liquid-replace="item">`
                if (imageUrl || !!null) {
                  html += `<img class="elImage" src="${imageUrl ?? null}"/>`
                } else if (false) {
                  html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
                }
                html += `</div></div>`
              }
            }
            html += `<div class="elProductCardInfoNameAndDescription">`
            if (customVariants.length > 1) {
              html += `<span class="elProductCardInfoName">${product.default_variant.name}</span>`
            } else {
              html += `<span class="elProductCardInfoName">${variant.name}</span>`
            }
            html += `<div class="elProductCardPrices"><span class="elProductCardInfoPrice elProductCardFinalPrice">${selected_price.name}</span>`
            if (show_compare_at_price) {
              const compare_at_amount = selected_price.compare_at_amount;
              if (compare_at_amount) {
                html += `<span class="elProductCardAnchorPrice">${compare_at_amount}</span>`
              }
            }
            html += `</div></div></div><div class="elProductCardInfoContainer"><div class="elProductCardInfoNameAndDescription">`
            if (show_description) {
              html += `<span class="elProductCardInfoDescription">${(product.description_override ?? variant.description ?? product.default_variant.description ?? "").replaceAll(`
`,"<br>")}</span>`
            }
            html += `</div><div class="elProductVariantSelectorWrapper">`
            if (customVariants.length > 1) {
              const c16 = product.sorted_property_values
              const fl17 = new CF2ForloopDrop(c16.length)
              for (const property_values of c16) {
                const forloop = fl17
                const property_id = property_values.property_id;
                const select_index = forloop.index0;
                html += `<div class="elProductVariantSelectorOptionWrapper"><span class="elProductVariantSelectorTitle">${product.all_properties[property_id]}</span><div class="elSelectWrapper"><select class="elProductInputControls elProductCardInput elProductVariantSelectorSelect" name="property_${property_id}">`
                const c18 = property_values.value_ids
                const fl19 = new CF2ForloopDrop(c18.length)
                for (const value_id of c18) {
                  const forloop = fl19
                  const new_value_ids = variant.property_value_ids.map((value, valueIndex) => select_index == valueIndex ? value_id : value).join(",");
                  const isSelected = variant.property_value_ids[select_index] == value_id;
                  const isUnavailable = product.property_values_variant_mapping[new_value_ids] == null;
                  html += `<option `
                  if (variant.property_value_ids[select_index] == value_id) {
                    html += `selected`
                  }
                  html += ` class="elProductVariantSelectorSelectOption" value="${value_id}">${product.all_properties_values[value_id]}`
                  if (isUnavailable) {
                    html += ` (unavailable)`
                  }
                  html += `</option>`
                  forloop.next();
                }
                html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div></div>`
                forloop.next();
              }
            }
            html += `</div><div class="elProductCardInfoSelectionAndPrices">`
            if (select_style == "quantity") {
              html += `<div class="elProductInputWrapper elProductCardQuantityContainer"><span>Quantity</span><div class="elProductInputControls"><button class="elProductCardInput">-</button><input class="elProductCardInput elProductCardShortInput" name="product" value="0" min="0" type="number"/><button class="elProductCardInput">+</button></div></div>`
            } else if (select_style == "quantity-forced") {
              html += `<div class="elProductInputWrapper elSelectWrapper elForcedQuantityWrapper"><span>Quantity</span><select name="product" class="elProductCardInput elProductInputControls">`
              const step = product.step ?? 2;
              const min = product.min ?? 0;
              const max = product.max ?? 5;
              const item_count_step_1 = max- min;
              const item_count_step_2 = item_count_step_1/ step;
              const item_count_step_3 = item_count_step_2| 0;
              const range = Array.apply(0, Array(item_count_step_3)).map((element, index) => index + 0);;
              const c20 = range
              const fl21 = new CF2ForloopDrop(c20.length)
              for (const i of c20) {
                const forloop = fl21
                const value = i* step+ min;
                html += `<option value="${value}">${value}</option>`
                forloop.next();
              }
              html += `</select><div class="elSelectArrow elForcedQuantitySelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
            } else {
              if (select_style == "multiple" && design_style != "classic") {
                html += `<div class="elProductCardModernInput"><span class="elButtonText"></span><span class="elButtonSub"></span></div>`
              }
            }
            const prices_count = variant.prices.length;
            if (prices_count > 1) {
              html += `<div class="elProductInputWrapper elSelectWrapper"><select name="variant_price" class="elProductCardPriceSelect elProductInputControls elProductCardInput">`
              const c22 = variant.prices
              const fl23 = new CF2ForloopDrop(c22.length)
              for (const price of c22) {
                const forloop = fl23
                html += `<option `
                if (selected_price.id == price.id) {
                  html += `selected`
                }
                html += ` data-compare-at-price="${price.compare_at_amount}" value="${price.id}">${price.name}</option>`
                forloop.next();
              }
              html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
            }
            html += `</div></div></div>`
          } else {
            html += `<div class="elProductCardSelectInput`
            if (design_style != "classic") {
              html += ` elHide`
            }
            html += `">`
            if (select_style == "single") {
              if (isChecked) {
                html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="true" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product" checked class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
              } else {
                html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="product"  class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
              }
            } else if (select_style == "multiple") {
              if (isChecked) {
                html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked="true"><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product" checked data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
                if (useCheckboxIcon) {
                  html += ` hide`
                }
                html += `"></div><i class="elCheckboxIconCheck fas fa-check`
                if (useCheckboxIcon == false) {
                  html += ` hide`
                }
                html += `"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
              } else {
                html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked=""><label class="elCheckboxLabel"><input type="checkbox" value="${product.id}" name="product"  data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
                if (useCheckboxIcon) {
                  html += ` hide`
                }
                html += `"></div><i class="elCheckboxIconCheck fas fa-check`
                if (useCheckboxIcon == false) {
                  html += ` hide`
                }
                html += `"></i></div></div><span class="elCheckboxText"></span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
              }
            }
            html += `</div>`
            html += `<div class="elProductCardRow">`
            if (show_image) {
              const imageUrl = variant.image ?? product.image;
              if (imageUrl && imageUrl != "") {
                html += `<div class="elProductCardImage"><div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
                if (true && !imageUrl && !null && false == false) {
                  html += ` forceHide`
                }
                html += `" data-liquid-replace="item">`
                if (imageUrl || !!null) {
                  html += `<img class="elImage" src="${imageUrl ?? null}"/>`
                } else if (false) {
                  html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
                }
                html += `</div></div>`
              }
            }
            html += `<div class="elProductCardInfoContainer"><div class="elProductCardInfoNameAndDescription"><div class="elProductCardInfoNameContainer">`
            if (customVariants.length > 1) {
              html += `<span class="elProductCardInfoName">${product.default_variant.name}</span>`
            } else {
              html += `<span class="elProductCardInfoName">${variant.name}</span>`
            }
            html += `<div class="elProductCardInfoPrice">`
            if (show_compare_at_price) {
              const compare_at_amount = selected_price.compare_at_amount;
              if (compare_at_amount) {
                html += `<span class="elProductCardAnchorPrice">${compare_at_amount}</span>`
              }
            }
            html += `<span class="elProductCardFinalPrice">${selected_price.name}</span></div>`
            if (show_description) {
              html += `<span class="elProductCardInfoDescription">${(product.description_override ?? variant.description ?? product.default_variant.description ?? "").replaceAll(`
`,"<br>")}</span>`
            }
            html += `<div class="elProductVariantSelectorWrapper">`
            if (customVariants.length > 1) {
              const c24 = product.sorted_property_values
              const fl25 = new CF2ForloopDrop(c24.length)
              for (const property_values of c24) {
                const forloop = fl25
                const property_id = property_values.property_id;
                const select_index = forloop.index0;
                html += `<div class="elProductVariantSelectorOptionWrapper"><span class="elProductVariantSelectorTitle">${product.all_properties[property_id]}</span><div class="elSelectWrapper"><select class="elProductInputControls elProductCardInput elProductVariantSelectorSelect" name="property_${property_id}">`
                const c26 = property_values.value_ids
                const fl27 = new CF2ForloopDrop(c26.length)
                for (const value_id of c26) {
                  const forloop = fl27
                  const new_value_ids = variant.property_value_ids.map((value, valueIndex) => select_index == valueIndex ? value_id : value).join(",");
                  const isSelected = variant.property_value_ids[select_index] == value_id;
                  const isUnavailable = product.property_values_variant_mapping[new_value_ids] == null;
                  html += `<option `
                  if (variant.property_value_ids[select_index] == value_id) {
                    html += `selected`
                  }
                  html += ` class="elProductVariantSelectorSelectOption" value="${value_id}">${product.all_properties_values[value_id]}`
                  if (isUnavailable) {
                    html += ` (unavailable)`
                  }
                  html += `</option>`
                  forloop.next();
                }
                html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div></div>`
                forloop.next();
              }
            }
            html += `</div><div class="elProductCardInfoSelectionAndPrices">`
            if (select_style == "quantity") {
              html += `<div class="elProductInputWrapper elProductCardQuantityContainer"><span>Quantity</span><div class="elProductInputControls"><button class="elProductCardInput">-</button><input class="elProductCardInput elProductCardShortInput" name="product" value="0" min="0" type="number"/><button class="elProductCardInput">+</button></div></div>`
            } else if (select_style == "quantity-forced") {
              html += `<div class="elProductInputWrapper elSelectWrapper elForcedQuantityWrapper"><span>Quantity</span><select name="product" class="elProductCardInput elProductInputControls">`
              const step = product.step ?? 2;
              const min = product.min ?? 0;
              const max = product.max ?? 5;
              const item_count_step_1 = max- min;
              const item_count_step_2 = item_count_step_1/ step;
              const item_count_step_3 = item_count_step_2| 0;
              const range = Array.apply(0, Array(item_count_step_3)).map((element, index) => index + 0);;
              const c28 = range
              const fl29 = new CF2ForloopDrop(c28.length)
              for (const i of c28) {
                const forloop = fl29
                const value = i* step+ min;
                html += `<option value="${value}">${value}</option>`
                forloop.next();
              }
              html += `</select><div class="elSelectArrow elForcedQuantitySelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
            } else {
              if (select_style == "multiple" && design_style != "classic") {
                html += `<div class="elProductCardModernInput"><span class="elButtonText"></span><span class="elButtonSub"></span></div>`
              }
            }
            const prices_count = variant.prices.length;
            if (prices_count > 1) {
              html += `<div class="elProductInputWrapper elSelectWrapper"><select name="variant_price" class="elProductCardPriceSelect elProductInputControls elProductCardInput">`
              const c30 = variant.prices
              const fl31 = new CF2ForloopDrop(c30.length)
              for (const price of c30) {
                const forloop = fl31
                html += `<option `
                if (selected_price.id == price.id) {
                  html += `selected`
                }
                html += ` data-compare-at-price="${price.compare_at_amount}" value="${price.id}">${price.name}</option>`
                forloop.next();
              }
              html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
            }
            html += `</div></div></div></div></div>`
          }
        }

      }

      this.replaceContent(html) 

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

registerComponent('ProductCard/V1', ProductCardV1)
window["ProductCardV1"] = ProductCardV1


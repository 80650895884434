
import OrderCart from "./order-cart-v1"
import { numberToCurrency } from '@yggdrasil/Utils/general'
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class OrderSummaryV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}


  selected_variants = []
  order_summary = {}
  existingTimer = 0
  oldRequestFetcher = null
  isSavedState = this.checkout_state === 'saved'

  mount() {
    document.addEventListener('OrderSummaryRender', (evt) => {
      this.renderNewSummaryValues(evt)
    })
    document.addEventListener('OrderSummaryStarted', (details) => {
      this.element.querySelector('.elSpinnerWrapper').dataset.loader = true
    })
    document.addEventListener('OrderSummaryFinished', (details) => {
      if (!details.detail || typeof details.detail != 'object' || details.detail.name != 'AbortError') {
        delete this.element.querySelector('.elSpinnerWrapper')?.dataset?.loader
      }
    })
  }

  renderNewSummaryValues(evt) {
    const shouldFetchOrderPreview = evt.detail?.options?.shouldFetchOrderPreview ?? true
    const productVariants = OrderCart.productVariants
    const details = OrderCart.details ?? {
      billing: { street: '', street2: '', city: '', postalCode: '', region: '', country: '' },
      shipping: { street: '', street2: '', city: '', postalCode: '', region: '', country: '' },
      customer: { email: '', first_name: '', last_name: '' },
    }
    const lineItems = productVariants.map(({ id, quantity, priceId }) => ({ quantity, variant_id: id, price_id: priceId }))
    const couponCodes = [OrderCart.couponCode].filter(Boolean)
    const billingAddress = details.billing
    const deliveryAddress = details.shipping
    const customerInfo = details.customer
    const fetchUrl = window.location.origin + window.location.pathname + '/order_preview'
    const billingSameAsShipping = details.billing_same_as_shipping
    
    if (this.oldRequestFetcher) {
      this.oldRequestFetcher.abort()
    }

    // Render initial selected stuff from user cart before retrieving other data
    // involving total, subtotal, etc
    const data = {
      productVariants: productVariants,
      billingAddress: billingAddress,
      shippingAddress: deliveryAddress,
      billingSameAsShipping: billingSameAsShipping,
      customerInfo: customerInfo,
    }
    this.renderSummary(data, shouldFetchOrderPreview)
    if (shouldFetchOrderPreview) {
      const requestFetcher = new globalThis.CFFetcher()
      this.oldRequestFetcher = requestFetcher
      requestFetcher.fetch(
        fetchUrl,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            order: {
              line_items: lineItems,
              coupon_codes: couponCodes,
              billing_address: billingAddress,
              delivery_address: deliveryAddress,
            },
          }),
        },
        {
          customEvent: 'OrderSummary',
        }
      )
      .then((res) => {
        if (requestFetcher.manuallyAborted) return
        return res.json()
      })
      .then((json) => {
        if (!json) return
        if (json.error == 'string') throw Error(json.error)
        const newData = {
          ...data,
          orderPreviewData: json,
        }
        this.renderSummary(newData, shouldFetchOrderPreview)
        this.oldRequestFetcher = null
        window.dispatchEvent(new CustomEvent('payments:update-rebilly-config', {
          detail: {
            orderSummary: newData
          }
        }))
      })
      .catch((error) => {
        this.oldRequestFetcher = null
        console.error(error)
      })
    }
  }

  formatAddress(addressObject) {
    const address = addressObject?.address ?? ''
    const address2 = addressObject?.address_2 ?? ''
    const country = addressObject?.country ?? ''
    const zip = addressObject?.zip ?? ''
    const state = addressObject?.state ?? ''
    const city = addressObject?.city ?? ''
    let formatedAddress = ''
    if (address) {
      formatedAddress = address + ', '
    } 
    if (address2) {
      formatedAddress += address2 + ', '
    } 
    if (city) {
      formatedAddress += city + ', '
    } 
    if (state) {
      formatedAddress += state + ' '
    } 
    if (zip) {
      formatedAddress += zip
    } 
    if (country) {
      formatedAddress += ', ' + country
    }

    return formatedAddress
  }
  
  formatName(nameObject) {
    const firstName = this.isSavedState ? this.contact?.first_name : nameObject?.first_name
    const lastName = this.isSavedState ? this.contact?.last_name : nameObject?.last_name
    if (firstName && lastName) {
      return firstName + ' ' + lastName
    } else {
      return firstName || lastName
    }
  }

  getAddress(address, type) {
    const isAddressEmpty = !Object.values(address ?? {}).some(x => x && x !== '')
    if (isAddressEmpty) {
      if (OrderCart.details?.[`${type}Enabled`]) {
        if (type == 'shipping') {
          return this.contact?.shipping_addresses?.[0]
        } else {
          return this.contact?.billing_addresses?.[0]
        }
      }
    } else {
      return address
    }
  }

  renderSummary(data, shouldUpdateOrderDetails) {
    const customerInfo = data.customerInfo
    const shippingAddress = this.getAddress(data.shippingAddress, 'shipping')
    const billingAddress = this.getAddress(data.billingAddress, 'billing')
    const productVariants = data.productVariants
    const orderPreview = data.orderPreviewData
    this.selected_variants = productVariants
    if (shouldUpdateOrderDetails) {
      this.order_summary.currency = productVariants[0]?.currency ?? '$'
      const currency = this.order_summary.currency

      this.order_summary.total = numberToCurrency(orderPreview?.total, currency) ?? 0
      this.order_summary.subtotal = numberToCurrency(orderPreview?.subtotalAmount, currency) ?? 0
      this.order_summary.taxes = numberToCurrency(orderPreview?.taxAmount, currency) ?? 0
      this.order_summary.shipping = numberToCurrency(orderPreview?.shippingAmount, currency) ?? 0
      this.order_summary.discount = numberToCurrency(orderPreview?.discountsAmount, currency) ?? 0
   }
    const formatedName = this.formatName(customerInfo)
    const formatedShippingAddress = this.formatAddress(shippingAddress)
    const formatedBillingAddress = this.formatAddress(billingAddress)
    this.customer_info.shipping_address = formatedShippingAddress
    this.customer_info.billing_address = data.billingSameAsShipping ? formatedShippingAddress : formatedBillingAddress
    this.customer_info.name = formatedName
    this.customer_info.phone = this.isSavedState ? this.contact?.phone_number : customerInfo?.phone
    this.customer_info.email = this.isSavedState ? this.contact?.email : customerInfo?.email
    this.render()
  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const contact = this.contact ?? null ;
const checkout_state = this.checkout_state ?? null ;
const selected_variants = this.selected_variants ?? [] ;
const order_summary = this.order_summary ?? {} ;
const customer_info = this.customer_info ?? {} ;

    const summaryHeadIcon = this.element.getAttribute('data-param-summaryHeadIcon');
const summaryCustomerDetailsIcon = this.element.getAttribute('data-param-summaryCustomerDetailsIcon');
  
      let html = '';
      {
        html += `<div data-page-element="Spinner/V1" class="elSpinnerWrapper layer-behind-modal-backdrop id-Spinner/V1"><div class="elSpinner"></div></div><div class="elOrderSummaryV2"><div class="elOrderSummaryHead"><i class="elOrderSummaryHeadIcon ${summaryHeadIcon}"></i><div data-page-element="Headline/V1" class="elHeadlineWrapper elOrderSummaryHeadTitle id-Headline/V1"><h1 class="elHeadline"><span>Order Summary</span></h1></div></div><div class="elOrderSummaryBody">`
        const c0 = selected_variants
        const fl1 = new CF2ForloopDrop(c0.length)
        for (const selected_variant of c0) {
          const forloop = fl1
          html += `<div class="elOrderSummaryProduct">`
          const imageUrl = selected_variant.image;
          if (imageUrl && imageUrl != "") {
            html += `<div class="elOrderSummaryProductImage"><div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
            if (true && !imageUrl && !null && false == false) {
              html += ` forceHide`
            }
            html += `" data-liquid-replace="item">`
            if (imageUrl || !!null) {
              html += `<img class="elImage" src="${imageUrl ?? null}"/>`
            } else if (false) {
              html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
            }
            html += `</div></div>`
          }
          html += `<div class="elOrderSummaryProductInfoWrapper"><span class="elOrderSummaryProductInfoName">${selected_variant.name}</span><div class="elOrderSummaryProductInfo"><span>Qty ${selected_variant.quantity}</span><span>${selected_variant.selected_price.name}</span></div></div></div>`
          forloop.next();
        }
        html += `</div>`
        if (customer_info.name == "" && customer_info.email == "" && customer_info.shipping_address == "" && customer_info.billing_address == "" && customer_info.phone == "") {
          html += `<div></div>`
        } else {
          html += `<div class="elOrderSummaryCustomerInfoWrapper"><div class="elOrderSummaryCustomerInfoHeader"><i class="elOrderSummaryCustomerInfoIcon ${summaryCustomerDetailsIcon}"></i><div data-page-element="Headline/V1" class="elHeadlineWrapper elOrderSummaryCustomerInfoTitle id-Headline/V1"><h1 class="elHeadline"><span>Customer Details</span></h1></div></div>`
          if (customer_info.name) {
            html += `<div class="elOrderSummaryCustomerInfo"><span class="elOrderSummaryCustomerInfoAttr elOrderSummaryCustomerInfoText">Name: </span><span class="elOrderSummaryCustomerInfoText">${customer_info.name}</span></div>`
          }
          if (customer_info.email) {
            html += `<div class="elOrderSummaryCustomerInfo"><span class="elOrderSummaryCustomerInfoAttr elOrderSummaryCustomerInfoText">Email: </span><span class="elOrderSummaryCustomerInfoText">${customer_info.email}</span></div>`
          }
          if (customer_info.phone) {
            html += `<div class="elOrderSummaryCustomerInfo"><span class="elOrderSummaryCustomerInfoAttr elOrderSummaryCustomerInfoText">Phone: </span><span class="elOrderSummaryCustomerInfoText">${customer_info.phone}</span></div>`
          }
          if (customer_info.shipping_address) {
            html += `<div class="elOrderSummaryCustomerInfo"><span class="elOrderSummaryCustomerInfoAttr elOrderSummaryCustomerInfoText">Shipping Address: </span><span class="elOrderSummaryCustomerInfoText">${customer_info.shipping_address}</span></div>`
          }
          if (customer_info.billing_address) {
            html += `<div class="elOrderSummaryCustomerInfo"><span class="elOrderSummaryCustomerInfoAttr elOrderSummaryCustomerInfoText">Billing Address: </span><span class="elOrderSummaryCustomerInfoText">${customer_info.billing_address}</span></div>`
          }
          html += `</div>`
        }
        html += `<div class="elOrderSummaryFoot"><div class="elOrderSummaryValueWrapper"><div data-page-element="SubHeadline/V1" class="elSubheadlineWrapper elOrderSummaryItem id-SubHeadline/V1"><h2 class="elSubheadline"><span> Subtotal </span></h2></div><div data-page-element="SubHeadline/V1" class="elSubheadlineWrapper elOrderSummaryPrice id-SubHeadline/V1"><h2 class="elSubheadline"><span>${order_summary.currency}${order_summary.subtotal}</span></h2></div></div><div class="elOrderSummaryValueWrapper"><div data-page-element="SubHeadline/V1" class="elSubheadlineWrapper elOrderSummaryItem id-SubHeadline/V1"><h2 class="elSubheadline"><span> Taxes </span></h2></div><div data-page-element="SubHeadline/V1" class="elSubheadlineWrapper elOrderSummaryPrice id-SubHeadline/V1"><h2 class="elSubheadline"><span>${order_summary.currency}${order_summary.taxes}</span></h2></div></div>`
        const has_physical_products = selected_variants.filter((value) => value["productType"] == "physical");
        if (has_physical_products && has_physical_products.length > 0) {
          html += `<div class="elOrderSummaryValueWrapper"><div data-page-element="SubHeadline/V1" class="elSubheadlineWrapper elOrderSummaryItem id-SubHeadline/V1"><h2 class="elSubheadline"><span> Shipping </span></h2></div>`
          if (order_summary.shipping > 0) {
            html += `<div data-page-element="SubHeadline/V1" class="elSubheadlineWrapper elOrderSummaryPrice id-SubHeadline/V1"><h2 class="elSubheadline"><span>${order_summary.currency}${order_summary.shipping}</span></h2></div>`
          } else {
            html += `<div data-page-element="SubHeadline/V1" class="elSubheadlineWrapper elOrderSummaryPrice id-SubHeadline/V1"><h2 class="elSubheadline"><span>FREE</span></h2></div>`
          }
          html += `</div>`
        }
        if (order_summary.discount && order_summary.discount > 0) {
          html += `<div class="elOrderSummaryValueWrapper"><div data-page-element="SubHeadline/V1" class="elSubheadlineWrapper elOrderSummaryItem id-SubHeadline/V1"><h2 class="elSubheadline"><span> Discount </span></h2></div><div data-page-element="SubHeadline/V1" class="elSubheadlineWrapper elOrderSummaryPrice id-SubHeadline/V1"><h2 class="elSubheadline"><span>- ${order_summary.currency}${order_summary.discount}</span></h2></div></div>`
        }
        html += `</div><div class="elOrderSummaryTotalWrapper"><div data-page-element="SubHeadline/V1" class="elSubheadlineWrapper elOrderSummaryTotalItem id-SubHeadline/V1"><h2 class="elSubheadline"><span> Total </span></h2></div><div data-page-element="SubHeadline/V1" class="elSubheadlineWrapper elOrderSummaryTotalPrice id-SubHeadline/V1"><h2 class="elSubheadline"><span>${order_summary.currency}${order_summary.total}</span></h2></div></div></div>`

      }

      this.replaceContent(html) 

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

registerComponent('OrderSummary/V2', OrderSummaryV2)
window["OrderSummaryV2"] = OrderSummaryV2

